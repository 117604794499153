<div class="min-w-[360px]">
  <div class="overflow-hidden h-[60px] flex items-center justify-between max-w-[1500px] mx-auto px-4 bg-[#000F9F]" [ngClass]="{'bg-cyan-900': (organizationType$ | async) === OrganizationTypeEnum.BUYER}">
    <div class="flex items-center gap-2 w-100 md:w-[283px]">
      <div class="relative h-[20px] px-4">
        <img class="h-full object-contain" src="assets/images/logo/bizgto_logo_h.png" alt="Logo">
      </div>

      <p class="text-sm text-gray-100 font-semibold ng-star-inserted tracking-wider border-l-4 pl-3" *ngIf="(organizationType$ | async) as type">
        {{ type === OrganizationTypeEnum.BUYER ? 'Comprador' : type === OrganizationTypeEnum.SUPPLIER ? 'Proveedor' : '-' }}
      </p>
    </div>

    <div class="text-white flex-1 gap-4 [&>span]:relative px-4 lg:px-0 hidden lg:flex">
      <ng-container *ngFor="let item of (this.menuItems$ | async)">
        <span class="cursor-pointer transition-all capitalize" [routerLink]="item.route" routerLinkActive="active" *ngIf="!item.hide">{{ item.text }}</span>
      </ng-container>
    </div>

    <div class="flex items-center gap-4 cursor-pointer">
      <!-- Profile -->
      <div class="text-white gap-2 items-center flex" nz-dropdown nzTrigger="click" [nzDropdownMenu]="menuProfileOptions" *ngIf="(this.user$ | async) as usr">
        <nz-avatar
          class="min-w-[30px] uppercase"
          [nzSize]="30"
          [nzSrc]="usr.profilePicture"
          [nzText]="usr.name | initialLetter"
        ></nz-avatar>
        <div>
          <p class="inline-flex gap-2">
            <span class="hidden md:block text-xs"> {{ usr.name }} {{ usr.lastName }} </span>
            <i class="las la-angle-down font-bold"></i>
          </p>
          <p class="hidden md:block text-xs text-gray-100 font-light" *ngIf="(organizationType$ | async) as type">
            {{ type === OrganizationTypeEnum.BUYER ? 'Comprador' : type === OrganizationTypeEnum.SUPPLIER ? 'Proveedor' : '-' }}
          </p>
        </div>
      </div>

      <!-- Hamburger -->
      <div class="font-bold text-white text-xl block lg:hidden" *ngIf="((this.sidebarHasItems$ | async) ?? []).length > 0">
        <i class="las la-bars" (click)="this.onLateralMenuToggle()"></i>
      </div>
    </div>
  </div>

</div>

<!-- dropdown notifications -->

<ng-template #notificationListTitleTpl>
  <p class="lg text-wz-black font-semibold">Notificaciones</p>
</ng-template>
<ng-template #notificationListTpl>
  <div class="w-[350px] overflow-y-auto min-h-[50px] max-h-[260px]">
    <div *ngFor="let notification of (notifications$ | async)" class=" overflow-hidden  flex items-center cursor-pointer hover:bg-wz-light-blue transition-all duration-100 px-4 py-2 justify-between">
      <div class="ml-2">
        <p class="text-wz-black text-sm" [ngClass]="notification.status === 'seen' ?'text-wz-black/[0.7]':''">{{ notification.text }}</p>
        <p class="text-xs text-wz-light-gray" [ngClass]="notification.status === 'noSeen' ? 'text-wz-primary': ''">{{ notification.createdAt }}</p>
      </div>
      <div class="flex items-center">
        <span *ngIf="notification.status === 'noSeen'" class="dot min-w-[8px] min-h-[8px] bg-wz-primary rounded-full mx-2"></span>
        <i class="las la-trash-alt text-lg text-wz-light-gray"></i>
      </div>
    </div>
    <div *ngIf="(notifications$ | async)?.length === 0">
      <div class="relative w-full p-5  flex justify-center">
        <i class="las la-search -rotate-90 text-[100px]  text-wz-light-blue opacity-60 relative "></i>
        <div class="absolute top-0 left-0 w-full h-full flex flex-col justify-center items-center">
          <h4 class="text-center">Sin notificaciones</h4>
          <p class="text-wz-black mt-4 text-center">Por el momento no tienes notificaciones</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>


<!-- dropdown menu account-information options -->
<nz-dropdown-menu #menuProfileOptions="nzDropdownMenu">
  <ul nz-menu class="p-2 bg-white" *ngIf="(this.user$ | async) as usr">
    <li nz-menu-item class="flex gap-2 items-center" routerLink="/profile/information">
      <div>
        <p class="text-gray-800 w-44" nz-typography nzEllipsis>{{ usr.name }} {{ usr.lastName }}</p>
        <p class="text-xs text-gray-500">Mi perfil</p>
      </div>
    </li>
    <li nz-menu-divider></li>
    <span class="cursor-pointer block text-wz-primary p-2 bg-blue-50 rounded w-full text-center" (click)="onChangeOrganizationType()" *ngIf="storageOrganization?.organizationType === OrganizationTypeEnum.BOTH; else organizationNotBothTypeTpl">{{ (organizationType$ | async) === OrganizationTypeEnum.BUYER ? 'Entrar como proveedor' : 'Entrar como comprador' }}</span>
    <ng-template #organizationNotBothTypeTpl>
      <span class="cursor-pointer block text-wz-primary p-2 bg-blue-50 rounded w-full text-center" (click)="onModalWantBeOtherType()">{{ (organizationType$ | async) === OrganizationTypeEnum.BUYER ? 'Quiero ser proveedor' : 'Quiero ser comprador' }}</span>
    </ng-template>
    <li nz-menu-divider></li>
    <button nz-button nzType="primary" nzSize="large" nzDanger class="flex gap-2 items-center mt-2 text-center justify-center w-full" (click)="onLogout()">
      <i class="las la-sign-out-alt text-base"></i>
      Cerrar sesión
    </button>
  </ul>
</nz-dropdown-menu>
