import {
  IDayDestructuringDate,
  IDestructuringDate,
  ITimeDestructuringDate,
} from '@shared/interfaces/destructuring-date.interface';

export class DateClass {
  static destructuringDate(date: Date | string): IDestructuringDate {
    const _date: Date = date instanceof Date ? date : new Date(date);

    return {
      date: _date,
      year: _date.getFullYear(),
      month: _date.getMonth(),
      day: _date.getDate(),
      hours: _date.getHours(),
      minutes: _date.getMinutes(),
      seconds: _date.getSeconds(),
      milliseconds: _date.getMilliseconds(),
    };
  }
  static destructuringShortDate(date: Date | string): IDayDestructuringDate {
    const _date: Date = date instanceof Date ? date : new Date(date);

    return {
      year: _date.getFullYear(),
      month: _date.getMonth(),
      day: _date.getDate()
    };
  }
  static createFromIDestructuringDate(date: Partial<IDestructuringDate>): Date {
    return new Date(
      date.year ?? new Date().getFullYear(),
      date.month ?? new Date().getMonth(),
      date.day ?? new Date().getDate(),
      date.hours ?? 0,
      date.minutes ?? 0,
      date.seconds ?? 0,
      date.milliseconds ?? 0
    );
  }

  static destructuringTime(date: Date | string): ITimeDestructuringDate {
    return {
      hours: new Date(date).getHours(),
      minutes: new Date(date).getMinutes(),
    };
  }

  static validate(date: string | Date | number): Date {
    if (date instanceof Date) return date;
    return new Date(date);
  }

  static isSameDay(date: Date, compareTo: Date): boolean {
    const a: IDestructuringDate = DateClass.destructuringDate(date);
    const b: IDestructuringDate = DateClass.destructuringDate(compareTo);
    return a.year === b.year && a.month === b.month && a.day === b.day;
  }

  static isSomeMinutes(date: Date, compareTo: Date): boolean {
    const a: IDestructuringDate = DateClass.destructuringDate(date);
    const b: IDestructuringDate = DateClass.destructuringDate(compareTo);
    return a.year === b.year && a.month === b.month && a.day === b.day && a.hours === b.hours && a.minutes === b.minutes;
  }

  static disabledEndHours(startDate: Date) {
    startDate = this.validate(startDate);
    const startHour: number = startDate.getHours();
    return (): number[] => {
      return Array.from({ length: startHour }, (_, index) => startHour - 1 - index);
    }
  }

  static disabledEndMinutes(startDate: Date) {
    startDate = this.validate(startDate);
    const startMinutes: number = startDate.getMinutes();
    return (currentHour: number): number[] => {
      if (currentHour === startDate.getHours()) {
        return Array.from({ length: startMinutes + 1 }, (_, index) => startMinutes - index);
      } else {
        return [];
      }
    }
  }

  static disabledStartHours(endDate: Date) {
    endDate = this.validate(endDate);
    const endHour: number = endDate.getHours();
    const endMinutes: number = endDate.getMinutes();
    return (): number[] => {
      return Array.from({ length: 10 - (endHour + (endMinutes === 0 ? 0 : 1)) }, (_, index) => (endMinutes === 0 ? 0 : 1) + index);
    }
  }

  static disabledStartMinutes(endDate: Date) {
    endDate = this.validate(endDate);
    const endMinutes: number = endDate.getMinutes();
    return (currentHour: number): number[] => {
      if (currentHour === endDate.getHours()) {
        return Array.from({ length: 61 - endMinutes }, (_, index) => endMinutes + index);
      } else {
        return [];
      }
    }
  }
}
