export const SERVER_MESSAGE_TRANSLATE: any = {
  // CLIENT
  'US0001': 'El apellido debe tener más de 3 caracteres.',
  'US0002': 'El nombre debe tener más de 3 caracteres.',
  'US0003': 'La contraseña debe tener al menos 8 caracteres.',
  'US0004': 'La contraseña no es los suficientemente fuerte.',
  'ORG121': 'El RFC de la empresa debería ser de tipo persona física.',
  'ORG122': 'El RFC de la empresa debería ser de tipo persona moral.',
  'ORG123': 'El RFC de la empresa es inválido.',
  'ORG124': 'El correo electrónico es inválido.',

  // SERVER
  'US001': 'Usuario no encontrado.',
  'US002': 'El correo electrónico ya se encuentra registrado.',
  'US003': 'El limite de envíos de código de validación ha sido alcanzado. Por favor, intenta más tarde.',
  'US004': 'El usuario ya es administrador.',
  'UPA001': 'El perfil ya existe.',
  'UPA002': 'User profile not found.',
  'UPP001': 'Se requiere al menos un teléfono.',
  'TPL0001': 'La categoría requiere al menos una extensión.',
  'DOC0001': 'Documento no encontrado.',
  'DOC0002': 'Usuario no encontrado.',
  'DOC0003': 'Documento requerido.',
  'RSVP001': 'Invitación no encontrada.',
  'RSVP002': 'La invitación ya ha sido previamente confirmada.',
  'ORG001': 'Organización no encontrada.',
  'ORG002': 'El usuario ya administra otra empresa.',
  'ORG003': 'La empresa ya existe.',
  'ORG004': 'El usuario ya pertenece a una empresa.',
  'ORG005': 'El correo electrónico ya se encuentra registrado.',
  'ORG007': 'La empresa ya ha aceptado los términos y condiciones.',
  'ORG008': 'La empresa ya ha sido validada.',
  'ORG009': 'La empresa ya ha sido rechazada.',
  'ORG010': 'La empresa se encuentra en la lista negra del SAT',
  'ORG11': 'La empresa no es proveedora.',
  'ORG12': 'El RFC ya se encuentra registrado',
  'ORG14': 'La empresa no esta validada.',
  'ORG15': 'La empresa ha sido eliminada.',
  'ORG16': 'Empresa eliminada',
  'ORG17': 'Requiere al menos una dirección.',
  'ORG18': 'El rfc es requerido.',
  'ORG19': 'La personalidad jurídica debe ser MORAL.',
  'OC001': 'Contacto no encontrado.',
  'CER001': 'No puede crear y seleccionar certificado a la vez.',
  'SNUM001': 'Número de proveedor duplicado.',
  'SNUM002': 'El año de matriculación debe ser menor a la fecha de expiración.',
  'MEM001': 'Colaborador no encontrado.',
  'MEM002': 'El colaborador no pudo ser eliminado.',
  'MEM003': 'El colaborador ya pertenece a una empresa.',
  'ROL001': 'Rol no encontrado.',
  'ROL002': 'El rol ya existe.',
  'ROL003': 'Los roles del sistema no pueden ser borrados.',
  'ROL004': 'El rol tiene usuarios asignados.',
  'ROL005': 'El nombre del rol debe ser único.',
  'ROL006': 'Los roles del sistema no pueden ser actualizados.',
  'PH001': 'Teléfono no encontrado.',
  'ADD001': 'Dirección no encontrada.',
  'ADD002': 'El estado debe ser valido.',
  'ADD003': 'El país debe ser valido.',
  'ADD004': 'La ciudad debe ser valida.',
  'ADD005': 'El estado es requerido.',
  'ADD006': 'El país es requerido.',
  'ADD007': 'La ciudad es requerida.',
  'PRO001': 'Producto no encontrado.',
  'PRO002': 'El producto no pudo ser publicado.',
  'PRO003': 'El producto no pudo ser rechazado.',
  'PRO004': 'El producto no pudo ser borrador.',
  'PRO005': 'El producto no pudo ser actualizado',
  'PRO006': 'El producto no pertenece al proveedor.',
  'PRO007': 'El servicio no pertenece al proveedor.',
  'SRV001': 'Servicio no encontrado.',
  'SRV002': 'El servicio no pudo ser publicado.',
  'SRV003': 'El servicio no pudo ser rechazado.',
  'SRV004': 'El servicio no pudo ser borrador.',
  'SRV005': 'El servicio no pudo ser actualizado.',
  'OPP001': 'La fecha de entrega debe ser al menos dentro de 2 días.',
  'OPP002': 'Oportunidad no encontrada.',
  'OPP003': 'Oportunidad finalizada.',
  'OPH002': 'Es necesario tener al menos un teléfono.',
  'MAT001': 'Resultado no encontrado.',
  'REQ001': 'Propuesta no encontrada.',
  'REQ002': 'Ya has respondido a la solicitud de cotización anteriormente',
  'REQ003': 'La propuesta ya ha sido respondida anteriormente.',
  'REQ004': 'La propuesta ha expirado.',
  'QUO001': 'Cotización no encontrada.',
  'QUO002': 'Respuesta no autorizada.',
  'QUO003': 'La cotización aún no ha sido respondida.',
  'JOIN001': 'La postulación ya ha sido solicitada anteriormente',
  'JOIN002': 'No puede aplicar a las oportunidades de la empresa.',
  'JOIN003': 'Propuesta no encontrada.',
  'OR001': 'Resolución no encontrada.',
  'OAS001': 'El proveedor no ha aceptado la oportunidad.',
  'OAS002': 'El proveedor ya ha sido unido a la oportunidad anteriormente.',
  'EVE001': 'Evento no encontrado.',
  'EVE002': 'El evento no pudo ser publicado.',
  'EVE003': 'El evento no pudo ser archivado.',
  'EFG001': 'Invitado no encontrado.',
  'EFG002': 'El invitado no pudo ser eliminado.',
  'EFG003': 'Invitados duplicados.',
  'DAT001': 'Rango de fechas no validos.',
  'DAT002': 'Hay colisiones en los horarios.',
  'DAT003': 'El horario no pudo ser eliminado.',
  'DAT004': 'No se pueden hacer cambios en los horarios.',
  'CR001': 'Conexión no encontrada.',
  'CR002': 'La conexión ya fue finalizada anteriormente.',
  'CR003': 'La conexión ya fue cancelada anteriormente.',
  'CR004': 'Enlace no enviado.',
  'CR005': 'La oportunidad no pertenece a la empresa organizadora.',
  'CR006': 'Las empresas tienen que ser diferentes.',
  'CR007': 'El proveedor no pertenece a la oportunidad.',
  'CR008': 'Solo una conexión activa a la vez entre dos empresas.',
  'CR009': 'La conexión ha finalizado o cancelado anteriormente.',
  'CR010': 'La conexión no ha finalizado o cancelado.',
  'CR011': 'La conexión no puede ser actualizada.',
  'CR012': 'La conexión no le pertenece al usuario.',
  'CR013': 'La conexión no puede ser cancelada.',
  'CR014': 'La conexión no puede ser finalizada.',
  'PCD001': 'Propuesta no disponible.',
  'PCD002': 'Ya ha sido aceptado un horario anteriormente.',
  'PCD003': 'Rango de fechas no validas.',
  'PCD004': 'Las fechas colisionan entre sí.',
  'PCD005': 'Fecha no disponible en el evento.',
  'PCD006': 'La fecha no puede ser eliminada.',
  'PCD007': 'Fecha no encontrada.',
  'CRF001': 'Los comentarios sobre la conexión ya han sido enviados anteriormente.',
  'GA001': 'Dependencia/organismo no encontrado.',
  'OGA002': 'La dependencia/organismo ya está registrada; favor de comunicarse con la administración en caso de dudas.',
  'OTP001': 'El código no existe o expiró.',
  'OTP002': 'El enlace ha expirado.',
  'TRA001': 'Capacitación no encontrada.',
  'TRA002': 'Ya no se puede participar en la capacitación.',
  'TRR001': 'Respuesta de capacitación no encontrada.',
  'TRR002': 'Ya se encuentra registrado a la capacitación.',
  'TRR003': 'Fecha aceptada no encontrada.',
  'TRR004': 'Fecha no disponible.',

  // OTHERS
  'L001': 'Credenciales inválidas',
  'L002': 'La sesión ha expirado',
  'L003': 'La sesión ha expirado.',
  'FILE001': 'El archivo es requerido.',

  find: (code: string) => {
    return SERVER_MESSAGE_TRANSLATE[code] ?? 'Ha ocurrido un error inesperado. Por favor, intenta nuevamente más tarde.';
  },
}
