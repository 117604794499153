import {
  IConnectionRequest,
  IConnectionRequestCreateDto,
  IConnectionRequestFilters,
  IConnectionRequestUpdateDto,
  IConnectionRequestWithRelations
} from '@connection-request/interfaces';
import {PrismaCountFilter, PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {BaseService} from '@shared/services';
import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';
import {firstValueFrom} from 'rxjs';
import {UtilityClass} from '@shared/class/utils';
import {omit as _omit} from 'lodash';
import {IProposedConnectionGuest} from '@connection-request/interfaces/proposed-connection-guest.interface';
import {
  IProposedConnectionDate,
  IProposedConnectionDateReserved
} from '@connection-request/interfaces/proposed-connection-date.interface';
import {ConnectionProposedByEnum, ConnectionRequestStatusEnum} from '@connection-request/enums';
import {IConnectionRequestFeedback} from '@connection-request/interfaces/connection-request-feedback.interface';

export interface IConnectionRequestState {
  list: IConnectionRequestWithRelations[];
  selectedId: string;
  count: number;
  proposedDateAvailabilityRequest: boolean;
  reservedHourByHost: IProposedConnectionDateReserved[];
  reservedHourByInvited: IProposedConnectionDateReserved[];
}

const _DEFAULT_DATA: IConnectionRequestState = {
  list: [],
  selectedId: '',
  count: 0,
  proposedDateAvailabilityRequest: false,
  reservedHourByHost: [],
  reservedHourByInvited: [],
}

export namespace ConnectionRequestActions {
  export class GetList {
    static readonly type: string = '[ConnectionRequest] Get Connection Request';
    constructor(public organizationId: string, public filter?: PrismaFilter<IConnectionRequest>) {}
  }

  export class GetCount {
    static readonly type: string = '[ConnectionRequest] Get Connection Request Count';
    constructor(public organizationId: string, public filter?: PrismaCountFilter<IConnectionRequest>) {}
  }

  export class GetSortedList {
    static readonly type: string = '[ConnectionRequest] Get Connection Request Sorted List';
    constructor(public organizationId: string, public filter?: IConnectionRequestFilters) {}
  }

  export class GetSortedCount {
    static readonly type: string = '[ConnectionRequest] Get Connection Request Sorted Count';
    constructor(public organizationId: string, public filter?: IConnectionRequestFilters) {}
  }

  export class GetById {
    static readonly type: string = '[ConnectionRequest] Get Connection Request By Id';
    constructor(public organizationId: string, public connectionRequestId: string, public filter?: PrismaFilter<IConnectionRequest>) {}
  }

  export class Post {
    static readonly type: string = '[ConnectionRequest] Post Connection Request';
    constructor(public organizationId: string, public connectionRequest: IConnectionRequestCreateDto) {}
  }

  export class Patch {
    static readonly type: string = '[ConnectionRequest] Patch Connection Request';
    constructor(public organizationId: string, public connectionId: string, public connectionRequest: IConnectionRequestUpdateDto) {}
  }

  export class AddGuest {
    static readonly type: string = '[ConnectionRequest] Add Guest Connection Request';
    constructor(public organizationId: string, public connectionId: string, public guests: any) {}
  }

  export class DeleteGuest {
    static readonly type: string = '[ConnectionRequest] Delete Guest Connection Request';
    constructor(public organizationId: string, public connectionId: string, public guestId: string) {}
  }

  export class CheckAvailabilityProposedDate {
    static readonly type: string = '[ConnectionRequest] Check Availability Proposed Date Connection Request';
    constructor(public organizationId: string, public connectionId: string, public dates: { startDate: string | Date, endDate: string | Date }) {}
  }

  export class AcceptProposedDate {
    static readonly type: string = '[ConnectionRequest] Accept Proposed Date Connection Request';
    constructor(public organizationId: string, public connectionId: string, public dateId: string) {}
  }

  export class DeleteProposedDate {
    static readonly type: string = '[ConnectionRequest] Delete Proposed Date Connection Request';
    constructor(public organizationId: string, public connectionId: string, public dateId: string) {}
  }

  export class DeleteAllProposedDates {
    static readonly type: string = '[ConnectionRequest] Delete All Proposed Date Connection Request';
    constructor(public organizationId: string, public connectionId: string) {}
  }



  export class Cancel {
    static readonly type: string = '[ConnectionRequest] Connection Request Cancel';
    constructor(public organizationId: string, public connectionRequestId: string) {}
  }

  export class Finish {
    static readonly type: string = '[ConnectionRequest] Connection Request Finish';
    constructor(public organizationId: string, public connectionRequestId: string) {}
  }

  export class GetReservedHours {
    static readonly type: string = '[ConnectionRequest] Get Reserved Hours';
    constructor(public year: number, public month: number, public day: number, public hostOrganizationId: string, public invitedOrganizationId: string, public excludeConnectionRequestId?: string) {}
  }

  export class Feedback {
    static readonly type: string = '[ConnectionRequest] Connection Request Feedback';
    constructor(public organizationId: string, public connectionRequestId: string, public feedback: string) {}
  }

  export class SetSelectedId {
    static readonly type: string = '[ConnectionRequest] Set Selected Id Connection Request';
    constructor(public connectionRequestId: string) {}
  }

  export class Clear {
    static readonly type: string = '[ConnectionRequest] Clear';
    constructor() {}
  }
}

@State<IConnectionRequestState>({
  name: 'ConnectionRequestState',
  defaults: _DEFAULT_DATA
})
@Injectable()
export class ConnectionRequestState {
  private readonly SERVER: string = environment.SERVER;

  constructor(
    private baseService: BaseService,
    private errorHandlerService: ErrorHandlerService,
  ) {}

  @Selector()
  static getConnectionRequestList(state: IConnectionRequestState): IConnectionRequestWithRelations[] {
    return state.list;
  }

  @Selector()
  static getConnectionRequestSelected({list, selectedId}: IConnectionRequestState): IConnectionRequestWithRelations | undefined {
    return list.find((connectionRequest: IConnectionRequest): boolean => connectionRequest.id === selectedId);
  }

  @Selector()
  static getCount({count}: IConnectionRequestState): number {
    return count;
  }

  @Selector()
  static getProposedDateAvailability({proposedDateAvailabilityRequest}: IConnectionRequestState): boolean {
    return proposedDateAvailabilityRequest;
  }

  @Selector()
  static getReservedHoursByHost({reservedHourByHost}: IConnectionRequestState): IProposedConnectionDateReserved[] {
    return reservedHourByHost;
  }

  @Selector()
  static getReservedHoursByInvited({reservedHourByInvited}: IConnectionRequestState): IProposedConnectionDateReserved[] {
    return reservedHourByInvited;
  }


  @Action(ConnectionRequestActions.GetList)
  async getList(
    {patchState}: StateContext<IConnectionRequestState>,
    {organizationId, filter}: ConnectionRequestActions.GetList
  ): Promise<void> {
    const response: DataBaseServiceResponse<IConnectionRequest[]> = await firstValueFrom(this.baseService.get<IConnectionRequest[]>(`${this.SERVER}/organizations/${organizationId}/connection-requests`, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      list: response.entity ?? []
    });
  }

  @Action(ConnectionRequestActions.GetCount)
  async getCount(
    {patchState}: StateContext<IConnectionRequestState>,
    {organizationId, filter}: ConnectionRequestActions.GetCount
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IConnectionRequest>(`${this.SERVER}/organizations/${organizationId}/connection-requests/count`, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      count: response.entity ?? 0
    })
  }

  @Action(ConnectionRequestActions.GetSortedList)
  async getSortedList(
    {patchState}: StateContext<IConnectionRequestState>,
    {filter, organizationId}: ConnectionRequestActions.GetSortedList
  ): Promise<void> {
    const response: DataBaseServiceResponse<IConnectionRequestWithRelations[]> = await firstValueFrom(this.baseService.get<IConnectionRequestWithRelations[]>(`${this.SERVER}/organizations/${organizationId}/connection-requests/sorted`, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: response.entity ?? []
    })
  }

  @Action(ConnectionRequestActions.GetSortedCount)
  async getSortedCount(
    {patchState}: StateContext<IConnectionRequestState>,
    {filter, organizationId}: ConnectionRequestActions.GetSortedCount
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IConnectionRequest>(`${this.SERVER}/organizations/${organizationId}/connection-requests/sorted/count`, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      count: response.entity ?? 0
    })
  }

  @Action(ConnectionRequestActions.GetById)
  async getById(
    {patchState}: StateContext<IConnectionRequestState>,
    {organizationId, connectionRequestId, filter}: ConnectionRequestActions.GetById
  ): Promise<void> {
    const response: DataBaseServiceResponse<IConnectionRequest> = await firstValueFrom(this.baseService.get<IConnectionRequest>(`${this.SERVER}/organizations/${organizationId}/connection-requests/${connectionRequestId}`, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      list: [response.entity!],
      selectedId: response.entity?.id,
    })
  }

  @Action(ConnectionRequestActions.Post)
  async post(
    {patchState, getState}: StateContext<IConnectionRequestState>,
    {organizationId, connectionRequest}: ConnectionRequestActions.Post
  ): Promise<void> {
    connectionRequest.eventId = connectionRequest.eventId || undefined; // Remove empty string

    const response: DataBaseServiceResponse<IConnectionRequest> = await firstValueFrom(this.baseService.post<IConnectionRequest>(`${this.SERVER}/organizations/${organizationId}/connection-requests`, connectionRequest));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      list: UtilityClass.updateOrPushItems<IConnectionRequest>(getState().list, response.entity!, 'id'),
      selectedId: response.entity?.id
    })
  }

  @Action(ConnectionRequestActions.Patch)
  async patch(
    {patchState, getState}: StateContext<IConnectionRequestState>,
    {organizationId, connectionId, connectionRequest}: ConnectionRequestActions.Patch
  ): Promise<void> {
    const response: DataBaseServiceResponse<IConnectionRequest> = await firstValueFrom(this.baseService.patch<IConnectionRequest>(`${this.SERVER}/organizations/${organizationId}/connection-requests/${connectionId}`, _omit(connectionRequest, ['id', 'status', 'invitedOrganizationId', 'opportunityId'])));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      list: UtilityClass.updateOrPushItems<IConnectionRequest>(getState().list, response.entity!, 'id'),
      selectedId: response.entity?.id
    })
  }

  @Action(ConnectionRequestActions.AddGuest)
  async addGuest(
    {patchState, getState}: StateContext<IConnectionRequestState>,
    {organizationId, connectionId, guests}: ConnectionRequestActions.AddGuest
  ): Promise<void> {
    const response: DataBaseServiceResponse<IProposedConnectionGuest[]> = await firstValueFrom(this.baseService.put<IProposedConnectionGuest[]>(`${this.SERVER}/organizations/${organizationId}/connection-requests/${connectionId}/guests`, guests));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    const list: IConnectionRequestWithRelations[] = [...getState().list];
    const connectionIndex: number = list.findIndex(({id}): boolean => id === getState().selectedId)
    if (connectionIndex === -1) return;
    list[connectionIndex].ProposedConnectionGuest = response.entity!;
    patchState({
      list
    })
  }

  @Action(ConnectionRequestActions.DeleteGuest)
  async deleteGuest(
    {patchState, getState}: StateContext<IConnectionRequestState>,
    {organizationId, connectionId, guestId}: ConnectionRequestActions.DeleteGuest
  ): Promise<void> {
    const response: DataBaseServiceResponse<IProposedConnectionGuest> = await firstValueFrom(this.baseService.delete<IProposedConnectionGuest>(`${this.SERVER}/organizations/${organizationId}/connection-requests/${connectionId}/guests/${guestId}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    const list: IConnectionRequestWithRelations[] = [...getState().list];
    const connectionIndex: number = list.findIndex(({id}): boolean => id === getState().selectedId)
    if (connectionIndex === -1) return;
    list[connectionIndex].ProposedConnectionGuest = UtilityClass.deleteItemByProp(list[connectionIndex].ProposedConnectionGuest!, 'id', guestId)
    patchState({
      list
    })
  }

  @Action(ConnectionRequestActions.CheckAvailabilityProposedDate)
  async checkAvailabilityProposedDate(
    {patchState}: StateContext<IConnectionRequestState>,
    {organizationId, connectionId}: ConnectionRequestActions.CheckAvailabilityProposedDate
  ): Promise<void> {
    const response: DataBaseServiceResponse<any> = await firstValueFrom(this.baseService.get<any>(`${this.SERVER}/organizations/${organizationId}/connection-requests/${connectionId}/dates/check-availability`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      proposedDateAvailabilityRequest: response.entity.available
    })
  }

  @Action(ConnectionRequestActions.AcceptProposedDate)
  async acceptProposedDate(
    {patchState, getState}: StateContext<IConnectionRequestState>,
    {organizationId, connectionId, dateId}: ConnectionRequestActions.AcceptProposedDate
  ): Promise<void> {
    const response: DataBaseServiceResponse<IProposedConnectionDate> = await firstValueFrom(this.baseService.patch<IProposedConnectionDate>(`${this.SERVER}/organizations/${organizationId}/connection-requests/${connectionId}/dates/${dateId}/accept`, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    const list: IConnectionRequestWithRelations[] = [...getState().list];
    const connectionIndex: number = list.findIndex(({id}): boolean => id === getState().selectedId)
    if (connectionIndex === -1) return;
    const proposedConnectionDateIndex: number = list[connectionIndex].ProposedConnectionDate!.findIndex(({id}) => id === dateId);
    if (proposedConnectionDateIndex === -1) return;

    const dates: IProposedConnectionDate[] = list[connectionIndex].ProposedConnectionDate ?? [];
    dates[proposedConnectionDateIndex] = response.entity!;

    list[connectionIndex] = {
      ...list[connectionIndex],
      status: ConnectionRequestStatusEnum.ACCEPTED,
      ProposedConnectionDate: [...dates],
    };

    patchState({
      list
    })
  }

  @Action(ConnectionRequestActions.DeleteProposedDate)
  async deleteProposedDate(
    {patchState, getState}: StateContext<IConnectionRequestState>,
    {organizationId, connectionId, dateId}: ConnectionRequestActions.DeleteProposedDate
  ): Promise<void> {
    const response: DataBaseServiceResponse<IProposedConnectionDate> = await firstValueFrom(this.baseService.delete<IProposedConnectionDate>(`${this.SERVER}/organizations/${organizationId}/connection-requests/${connectionId}/dates/${dateId}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
   /* const list: IConnectionRequestWithRelations[] = [...getState().list];
    const connectionIndex: number = list.findIndex(({id}): boolean => id === getState().selectedId)
    if (connectionIndex === -1) return;
    list[connectionIndex].ProposedConnectionDate = UtilityClass.deleteItemByProp(list[connectionIndex].ProposedConnectionDate!, 'id', dateId);
    patchState({
      list
    })*/
  }

  @Action(ConnectionRequestActions.DeleteAllProposedDates)
  async deleteAllProposedDates(
    {patchState, getState}: StateContext<IConnectionRequestState>,
    {organizationId, connectionId}: ConnectionRequestActions.DeleteAllProposedDates
  ): Promise<void> {
    const response: DataBaseServiceResponse<IProposedConnectionDate> = await firstValueFrom(this.baseService.delete<IProposedConnectionDate>(`${this.SERVER}/organizations/${organizationId}/connection-requests/${connectionId}/dates`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    const list: IConnectionRequestWithRelations[] = [...getState().list];
    const connectionIndex: number = list.findIndex(({id}): boolean => id === getState().selectedId)
    if (connectionIndex === -1) return;
    list[connectionIndex].ProposedConnectionDate = []
    patchState({
      list
    })
  }

  @Action(ConnectionRequestActions.Cancel)
  async cancel(
    {patchState, getState}: StateContext<IConnectionRequestState>,
    {organizationId, connectionRequestId}: ConnectionRequestActions.Cancel
  ): Promise<void> {
    const response: DataBaseServiceResponse<IConnectionRequest> = await firstValueFrom(this.baseService.patch<IConnectionRequest>(`${this.SERVER}/organizations/${organizationId}/connection-requests/${connectionRequestId}/cancel`, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    const list: IConnectionRequestWithRelations[] = [...getState().list];
    const connectionIndex: number = list.findIndex(({id}): boolean => id === getState().selectedId)
    if (connectionIndex === -1) return;
    list[connectionIndex].status = ConnectionRequestStatusEnum.CANCELLED;
    patchState({
      list
    })
  }

  @Action(ConnectionRequestActions.Finish)
  async finish(
    {patchState, getState}: StateContext<IConnectionRequestState>,
    {organizationId, connectionRequestId}: ConnectionRequestActions.Finish
  ): Promise<void> {
    const response: DataBaseServiceResponse<IConnectionRequest> = await firstValueFrom(this.baseService.patch<IConnectionRequest>(`${this.SERVER}/organizations/${organizationId}/connection-requests/${connectionRequestId}/finish`, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    const list: IConnectionRequestWithRelations[] = [...getState().list];
    const connectionIndex: number = list.findIndex(({id}): boolean => id === getState().selectedId)
    if (connectionIndex === -1) return;
    list[connectionIndex].status = ConnectionRequestStatusEnum.FINISHED;
    patchState({
      list
    })
  }

  @Action(ConnectionRequestActions.GetReservedHours)
  async getReservedHours(
    {patchState}: StateContext<IConnectionRequestState>,
    {year, month, day, hostOrganizationId, invitedOrganizationId, excludeConnectionRequestId}: ConnectionRequestActions.GetReservedHours
  ): Promise<void> {
    const response: DataBaseServiceResponse<any> = await firstValueFrom(this.baseService.get<any>(`${this.SERVER}/connection-requests/dates/busy?year=${year}&month=${month}&day=${day}&hostOrganizationId=${hostOrganizationId}&invitedOrganizationId=${invitedOrganizationId}&excludeConnectionRequestId=${excludeConnectionRequestId ?? void 0}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    const hours: IProposedConnectionDateReserved[] = response.entity.busyDates || [];
    patchState({
      reservedHourByHost: hours.filter((hour: IProposedConnectionDateReserved): boolean => hour.proposedBy === ConnectionProposedByEnum.HOST_ORGANIZATION),
      reservedHourByInvited: hours.filter((hour: IProposedConnectionDateReserved): boolean => hour.proposedBy === ConnectionProposedByEnum.INVITED_ORGANIZATION),
    })
  }


  @Action(ConnectionRequestActions.Feedback)
  async feedback(
    {patchState, getState}: StateContext<IConnectionRequestState>,
    {organizationId, connectionRequestId, feedback}: ConnectionRequestActions.Feedback
  ): Promise<void> {
    const response: DataBaseServiceResponse<IConnectionRequestFeedback> = await firstValueFrom(this.baseService.post<IConnectionRequestFeedback>(`${this.SERVER}/organizations/${organizationId}/connection-requests/${connectionRequestId}/feedback`, feedback));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    const list: IConnectionRequestWithRelations[] = [...getState().list];
    const connectionIndex: number = list.findIndex(({id}): boolean => id === getState().selectedId)
    if (connectionIndex === -1) return;
    list[connectionIndex].ConnectionRequestFeedback = [response.entity!];
    patchState({
      list
    })
  }

  @Action(ConnectionRequestActions.SetSelectedId)
  async setSelectedId(
    {patchState}: StateContext<IConnectionRequestState>,
    {connectionRequestId}: ConnectionRequestActions.SetSelectedId
  ): Promise<void> {
    patchState({
      selectedId: connectionRequestId
    })
  }

  @Action(ConnectionRequestActions.Clear)
  async clear(
    {patchState}: StateContext<IConnectionRequestState>,
  ): Promise<void> {
    patchState(_DEFAULT_DATA)
  }

}
