import {Component, Input, OnInit, Pipe, PipeTransform} from '@angular/core';
import {CommonModule} from '@angular/common';
import {IEventDateGroup, IEventDateTime} from '@event/interfaces';
import {PLATFORM_CONFIG} from '@shared/config';
import {GetIsRangeDatesPipe} from '@event/pipes/get-is-range-dates.pipe';
import {NzDividerModule} from 'ng-zorro-antd/divider';
import {NzTagModule} from 'ng-zorro-antd/tag';
import {format} from 'date-fns';
import {TransformEventDateTextPipe} from '@shared/pipes';
import {IAnyObject} from '@shared/interfaces';
import {IEventActivity} from '@event-extended/interfaces';

export interface IDateTime {
  hours: number;
  minutes: number;
}

export interface IEventDateRequestTime {
  start: IDateTime;
  end: IDateTime;
}


export interface IEventDateRequest {
  startDate: Date;
  endDate: Date;
  timeGroup: IEventDateRequestTime[];
  allDay?: boolean;
}

export interface IEventDateLocalTime {
  start: Date;
  end: Date;
  error?: IAnyObject<boolean>;
}

export interface IEventDateLocal {
  startDate: Date;
  endDate: Date;
  timeGroup: IEventDateLocalTime[];
  allDay?: boolean;
  error?: IAnyObject<boolean>;
}

@Pipe({name: 'getTimeGroupFormat', standalone: true })
export class GetTimeGroupFormat implements PipeTransform {
  transform(timeGroup: IEventDateTime): string {
    const startDate: number = new Date().setHours(timeGroup.start.hours, timeGroup.start.minutes);
    const endDate: number = new Date().setHours(timeGroup.end.hours, timeGroup.end.minutes);
    return `${format(startDate, 'hh:mm a')} - ${format(endDate, 'hh:mm a')}`
  }
}

@Pipe({name: 'getActivityTimeFormat', standalone: true })
export class GetActivityTimeFormat implements PipeTransform {
  transform(eventActivity: IEventActivity): string {
    const startDate: Date = new Date(eventActivity.startTime);
    const endDate: Date = new Date(eventActivity.endTime);
    return `${format(startDate, 'hh:mm a')} - ${format(endDate, 'hh:mm a')}`
  }
}



@Component({
  selector: 'event-dates',
  template: `
    <div class="grid grid-cols-1 gap-2">
      <div class="col-span-1">
        <p class="text-wz-gray text-sm">Fechas del evento</p>
      </div>
      <div class="col-span-1 grid grid-cols-12 gap-4">
        <div class="col-span-12 sm:col-span-6 md:col-span-6 lg:col-span-6 rounded-lg border-wz-border border flex flex-col p-2 gap-2" *ngFor="let eventDateGroup of eventDatesGroup">
          <div class="text-center">
            <p class="text-wz-black text-sm">{{ eventDateGroup | transformEventDateText }}</p>
          </div>
          <!--  <div *ngIf="(eventDateGroup | getIsRangeDates) as eventDate" class="flex items-center gap-2 justify-around">
            <div>
              <span class="text-xs text-wz-gray">Inicio</span>
              <p class="text-sm text-wz-black " >
                {{ eventDate.startDate | date: PLATFORM_CONFIG.DATE.FORMAT }}
              </p>
            </div>
            <i *ngIf="eventDate.endDate" class="las la-long-arrow-alt-right text-xl rotate-0 text-wz-black"></i>
            <div *ngIf="eventDate.endDate">
              <span class="text-xs text-wz-gray">Finalización</span>
              <p class="text-sm text-wz-black " >
                {{ eventDate.endDate | date: PLATFORM_CONFIG.DATE.FORMAT }}
              </p>
            </div>
          </div>-->

          <nz-divider class="!bg-wz-border my-0"></nz-divider>

            @if (eventDateGroup.allDay) {
              <span class="mx-auto text-xs bg-wz-light-blue text-center text-wz-primary rounded-3xl py-1 px-2"> Todo el día </span>
            } @else {
              <div class="grid gap-2 grid-cols-2">
                @for (innerDate of eventDateGroup.timeGroup; track innerIndex; let innerIndex = $index) {
                  <span class="col-span-1  text-xs bg-wz-light-blue text-center text-wz-primary rounded-3xl p-1"> {{ innerDate | getTimeGroupFormat }} </span>
                }
              </div>
            }
        </div>
      </div>
    </div>
  `,
  standalone: true,
  imports: [
    CommonModule,
    GetIsRangeDatesPipe,
    NzDividerModule,
    NzTagModule,
    GetTimeGroupFormat,
    TransformEventDateTextPipe,
  ],

})

export class EventDatesComponent implements OnInit {
  @Input() eventDatesGroup!: IEventDateGroup[];
  constructor() {}

  ngOnInit(): void {}

  protected readonly PLATFORM_CONFIG = PLATFORM_CONFIG;
}
